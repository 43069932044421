.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #e392f7;
}

.countdown {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.countdown > * {
  margin: 0;
  padding: 10px;
}

.dayof {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dayof > * {
  margin: 0;
  padding: 10px;
}

.error {
  color: red;
}
